import React from "react";
import Layout from "@layouts/Layout";

import SaasTemplate from "./Saas";

const SaasPage = ({ data: initialData }) => {
  const data = initialData?.markdownRemark?.frontmatter;

  const seo = {
    ...(data?.seo || {}),
    image: data?.seo?.image?.childImageSharp?.fluid?.src,
  };

  return (
    <Layout seo={seo}>
      <SaasTemplate data={data} />
    </Layout>
  );
};

export default SaasPage;

export const saasPageQuery = graphql`
  query PageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Saas" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          subTitle
        }
        sections {
          title
          text
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
